<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div v-if="organiserSlug == 'qub'" class="justify-center">
      <img class="mx-auto h-50" src="https://imgs.search.brave.com/cUc6ZH6FjB78kcNkN6l4QEioNddtZ7K55mnHlOk4sHE/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pLnBp/bmltZy5jb20vb3Jp/Z2luYWxzL2Y1L2Zm/L2U4L2Y1ZmZlODli/YjRlNTFlNDZiZjQ4/OTQ2NWJkNzJkOGVk/LmpwZw" width="25%" />
    </div>
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-2xl md:text-2xl"
          >
            <span class="block text-mygrad">Let's get started</span>
          </h1>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        class="space-y-4 text-gray-700"
        @submit.prevent="handleSubmit(submit)"
      >
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <ValidationProvider
              rules="required|min:3"
              v-slot="{ errors, valid }"
            >
              <label class="block mb-1" for="formGridCode_name"
                >Full name</label
              >
              <input
                class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                type="text"
                id="formGridCode_name"
                name="name"
                v-model="form.name"
                placeholder="Your Name to be shown at graduation"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              />
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <ValidationProvider
              name="Email Address"
              rules="required|email|personal_email"
              v-slot="{ valid, errors }"
              vid="email"
            >
              <label class="block mb-1" for="formGridCode_email"
                >Email Address</label
              >
              <input
                ref="email"
                class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                type="email"
                id="formGridCode_email"
                name="email"
                v-model="form.email"
                placeholder="john.doe@example.com"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              /><span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <ValidationProvider
              name="Confirmation Email Address"
              rules="required|confirmed:email"
              v-slot="{ valid, errors }"
            >
              <label class="block mb-1" for="formGridCode_email_conf"
                >Confirm Email Address</label
              >
              <input
                class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                type="email"
                id="formGridCode_email_conf"
                name="confirmed_email"
                v-model="form.confirmEmail"
                placeholder="john.doe@example.com"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              /><span>{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <!-- TODO ADD dates and list faculties -->
        <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2">
            <label class="block mb-1" for="formGridCode_event"
              >Your Graduation Date</label
            >
            <select
              v-model="form.event"
              class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
              id="formGridCode_event"
              name="event"
            >
              <option disabled :value="null">
                Select your graduation date
              </option>

              <option
                v-for="ceremony in events"
                :value="ceremony['id']"
                :key="ceremony['id']"
              >
                {{ ceremony["datetime"] | formatDate }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-for="(field, index) in applyConditions()"
          :index="index"
          :key="field.name"
          class="flex flex-wrap -mx-2 space-y-4 md:space-y-0"
        >
          <div class="w-full px-2">
            <ValidationProvider :rules="field.rules" v-slot="{ valid, errors }">
              <label
                class="block mb-1"
                style="white-space: pre-wrap"
                :for="field.name"
                >{{ field.title }}</label
              >
              <div v-if="field.type == 'presentationOnly'"></div>
              <div v-else-if="field.type == 'markdown'" class="markdownHtmlCustom" v-html="markdownHtml(field.content)"></div>
              <div v-else-if="field.type == 'radio'">
                <div v-for="(choice, c_index) in field.choices" :key="c_index">
                  <label :for="choice.value">{{ choice.text }} </label>
                  <input
                    type="radio"
                    v-model="getField(field).value"
                    :id="choice.value"
                    :name="field.name"
                    :value="choice.value"
                    :disabled="!field.enabled"
                  />
                </div>
              </div>
              <div v-else-if="field.type == 'select'">
                 <select
                  :id="field.name"
                  :name="field.name"
                  :disabled="!field.enabled"
                  class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                  v-model="getField(field).value"
                  :ref="field.name"
                >
                  <option selected disabled :value="null">
                    {{ field.default }}
                  </option>

                  <option
                    v-for="(choice, c_index) in field.choices"
                    :key="c_index"
                    :value="choice.value"
                    :data-unique="'unique' in choice ? choice.unique : choice.value"
                  >
                    {{ choice.text }}
                  </option>
                </select>
              </div>
              <div v-else-if="field.type == 'multi-select'">
                <div v-for="(choice, c_index) in field.choices" :key="c_index">
                  <input
                    type="checkbox"
                    v-model="getField(field).value"
                    :id="choice.value"
                    :name="field.name"
                    :value="choice.value"
                    :disabled="!field.enabled"
                  />
                  <label :for="choice.value">{{ choice.text }} </label>
                 
                </div>
              </div>
              <input
                v-else
                class="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                :type="field.type"
                :id="field.name"
                :name="field.name"
                :disabled="!field.enabled"
                :placeholder="field.placeholder"
                v-model="getField(field).value"
                v-bind:class="{
                  'border-red-700 text-red-900': errors.length > 0,
                  'border-green-700': valid,
                }"
              />
            </ValidationProvider>
          </div>
        </div>

        <button
          type="submit"
          class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Continue
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>
<style>
.markdownHtmlCustom a {
  
  text-decoration: underline !important; 
}
</style>
<script>
import marked from "marked";
import { extend } from "vee-validate";
import {
  required,
  min,
  max,
  email,
  regex,
  confirmed,
} from "vee-validate/dist/rules";
import structuredClone from '@ungap/structured-clone';

extend("personal_email", {
  message: (field) =>
    "The " +
    field +
    " value is not a personal email, please enter an email not ending in ac.uk.",
  validate: (value) => !/^.*((\.ac\.uk)|(bpp\.com))$/.test(value),
});

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min", min);
extend("confirmed", {
  ...confirmed,
  message: "Your email addresses do not match",
});
extend("regex", regex);
extend("max", max);
extend("email", email);
export default {
  name: "RegistrationForm",
  props: {
    events: Array,
    additionalFieldsInput: Array,
    name: String,
    event: String,
    email: String,
    organiserSlug: String
  },
  data: function () {
    return {
      form: {
        name: "",
        event: null,
        email: null,
        confirmEmail: null,
        additionalFields: [],
      },
    };
  },
  created: function () {
    this.form.name = this.name;
    this.form.event = this.event;
    this.form.email = this.email;
    this.form.confirmEmail = this.email;
    this.initializeAdditionalFields(this.additionalFieldsInput);
    this.form.additionalFields.map((item) => {
      if (item.regex != null) {
        item.regex = new RegExp(item.regex);
      }
    });
  },
  methods: {
    initializeAdditionalFields(fields) {
      fields.forEach(field => {
        let existingField = this.form.additionalFields.find(f => f.name === field.name);
        if (!existingField) {
          this.form.additionalFields.push({ 
            ...field, 
            ...(field.type === 'multi-select' && { value: [] })
          });
        }
      });
    },
    sortAlphabetically(l) {
      return l.sort((a,b) => a.text - b.text)
    },
    markdownHtml(value) {
      return marked.parse(value);
    },
    mapFields() {
      var mappedAdditionalFields = {};
      this.form.additionalFields.forEach((item) => {
        mappedAdditionalFields[item["name"]] = item["value"];
      });
      return mappedAdditionalFields;
    },
    submit() {
      this.$emit("submit", this.form);
    },
    getRulesObject(field) {
      return field.rules;
    },
    getField(field) {
      return this.form.additionalFields.filter((f) => f.name == field.name)[0];
    },
    applyConditions() {
      var output_fields = structuredClone(this.additionalFieldsInput);
      for (let field_index in output_fields) {
        let field = output_fields[field_index];
        if (!("enabled" in field)) {
          field["enabled"] = true;
        }
        if(!("display" in field)) {
          field["display"] = true;
        }
        if ("conditions" in field) {
          for (let condition_index in field.conditions) {
            let condition = field.conditions[condition_index];
            var value_to_check;
            var possible_fields = this.form.additionalFields.filter((element) => {
                  return element.name == condition.field;
              })
            if (condition.comparator == "equals") {
              if (possible_fields.length == 1) {
                value_to_check = possible_fields[0].value
              }
              else {
                value_to_check = this.form[condition.field]
              }
              console.log(value_to_check);
              if (value_to_check == condition.value) {
                console.log(condition)
                switch (condition.operation.type) {
                  case "show":
                    field[condition.operation.relates_to] = field[
                      condition.operation.relates_to
                    ].filter((element, index) => {
                      console.log(element);
                      return condition.operation.indexes.includes(index);
                    });
                    break;
                  case "enabled": 
                    field[condition.operation.relates_to] = condition.operation.enabled;
                    break;
                }
              }
            }
            else if (condition.comparator == "property_equals") {
              console.log(this.$refs)
              try {
                value_to_check = this.$refs[condition.field][0].selectedOptions[0].dataset[condition.property]
              }
              catch {
                break
              }
              console.log(value_to_check);
              if (value_to_check == condition.value) {
                console.log(condition)
                switch (condition.operation.type) {
                  case "show":
                    field[condition.operation.relates_to] = field[
                      condition.operation.relates_to
                    ].filter((element, index) => {
                      console.log(element);
                      return condition.operation.indexes.includes(index);
                    });
                    break;
                  case "enabled": 
                    field[condition.operation.relates_to] = condition.operation.enabled;
                    break;
                }
              } 
            }
          }
        }
      }
      console.log(output_fields);
      return output_fields.filter((i) => i.display);
    },
  },
};
</script>
