<template>
  <div class="md:w-1/2 sm:w-full mx-auto">
    <div class="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
      <div class="mt-8 mx-auto max-w-7xl px-4 sm:mt-4 sm:px-6">
        <div class="text-center">
          <h1
            class="
              text-4xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-5xl
              md:text-6xl
            "
          >
            <span class="block">Register now</span>
            <span class="block text-mygrad">for your GraduationClip</span>
          </h1>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            <b>Congratulations</b> on graduating from <span v-if="organiserName == 'University of Sheffield'">the </span>{{ organiserName }}.
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Register here for your free GraduationClip
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Your personalised GraduationClip has been funded by <span v-if="organiserName == 'University of Sheffield'">the </span>
            {{ organiserName }} and is the perfect gift to share your big moment
            centre stage with the world.
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            Your personal data will be used to process your registration for your GraduationClip, support your
            experience throughout this website, and for other purposes described
            in our privacy policy.
          </p>
          <p
            class="
              mt-3
              max-w-md
              mx-auto
              text-base text-gray-500
              sm:text-lg
              md:mt-5 md:text-xl md:max-w-3xl
            "
          >
            <span v-if="organiserName == 'University of Leeds'">Your details will be passed on to the Alumni team at the University to provide you with event invites, alumni and department news relevant to you. You can view the Alumni privacy notice here.</span>
            <span v-else>Your details will be passed on to the <span v-if="organiserName == 'Newcastle University'">Newcastle University Advancement Office</span><span v-else-if="organiserName == 'University of Sheffield'">Alumni Relations & Campaigns team</span><span v-else>Alumni Office</span>, who will hold
            your details to personalise your graduate experience.</span><br/> For
            information on how MyGraduationClip will use your details please see our privacy
            statement <b><a href="https://www.mygraduationfilm.com/privacy-policy/">here</a></b>.
          </p>

          <button
            type="submit"
            @click="$emit('submit', form)"
            class="
              inline-flex
              mt-3
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            Register for your GraduationClip →
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitlePage",
  props: { title: String, organiserName: String },
  data: function () {
    return {
      form: {
        name: "",
      },
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>
