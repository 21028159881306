// src/router.js
import VueRouter from "vue-router";
import organisers from "./config.js";
import RegisterForm from "./components/Form.vue";
import FindStudentProcess from "./components/Submissions/FindStudentProcess";
import Thumbnails from "./components/Submissions/Thumbnails";
import StoreSuccess from "./components/Purchases/StoreSuccess";
import DeliveryPage from "./components/DeliveryPage.vue";
import StoreCancel from "./components/Purchases/StoreCancel";
import PurchaseForm from "./components/Purchases/Form.vue";
import Login from "./components/Admin/Login.vue";
import AutomaticSubmissionAccept from "./components/AutomaticSubmissionAccept.vue";
import Admin from "./components/Admin/Admin.vue";
import Upload from "./components/Admin/Upload.vue";
import CompleteShopifyReg from "./components/Purchases/Shopify/CompleteReg.vue"
import Update from "./components/Registration/Update.vue"
import AudioTool from "./components/Submissions/AudioTool/AudioTool.vue"
import Snapshot from "./components/Snapshot/Snapshot.vue"
import PrivacyPolicy from "./components/PrivacyPolicy.vue"
import DigitalDownloadForm from "./components/Purchases/DigitalDownload/DigitalDownloadForm.vue"
import DigitalDownloadDelivery from "./components/DigitalDownload/DigitalDownloadDelivery.vue"
import Help from "./components/Submissions/Help.vue"
import NottinghamListing from "./components/Purchases/NottinghamListing.vue"

const partialroutes = [
  
  {
    path: "/",
    component: RegisterForm,
    name: "registerRoot",
    auth: false

    //props: { organiser: organisers.kings }
  },
  {
    path: "/snapshot/:uni",
    component: Snapshot,
    name: "snapshotForm",
    auth: false,
    props: { name: "" }
  },
  {
    path: "/:uni-register",
    component: RegisterForm,
    name: "register",
    auth: false

    //props: { organiser: organisers.kings }
  },
  {
    path: "/clip/:uni/:id",
    component: DeliveryPage,
    props: {
    },
    auth: false

  },
  {
    path: "/clip/:uni/:id/audio",
    component: AudioTool,
    props: {
    },
    auth: false

  },
  {
    path: "/clip/:uni/:id/help",
    component: Help,
    props: {
    },
    auth: false

  },
  {
    path: "/digital-download/:uni/:id/download",
    component: DigitalDownloadDelivery,
    props: {
    },
    auth: false

  },
  {
    path: "/submission/:id",
    component: FindStudentProcess,
    auth: false

  },

  {
    path: "/submission/:id/automatic",
    component: AutomaticSubmissionAccept,
    auth: false

  },
  {
    path: "/store/:uni",
    component: NottinghamListing,
    auth:false
  },
  
  {
    path: "/store/:uni/success",
    component: StoreSuccess,
    auth: false

  },
  {
    path: "/store/:uni/cancel",
    component: StoreCancel,
    auth: false

  },
  {
    path: "/store/:uni/purchase",
    component: PurchaseForm,
    auth: false
  },
  {
    path: "/store/:uni/purchase/digital-download",
    component: DigitalDownloadForm,
    auth: false
  },
  {
    path: "/store/:uni/complete-registration/:id",
    component: CompleteShopifyReg,
    auth: false
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [

      {
        path: "upload",
        component: Upload,
        name: "upload"
      }
    ],
    auth: true
  },

  {
    path: "/admin/login",
    component: Login,
    name: "login",
    auth: false

  },

  {
    path: "/thumbnails/:uni/:id",
    component: Thumbnails,
    auth: false
  },
  {
    path: "/:uni/registration/:id/update",
    component: Update,
    auth: false
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    auth: false 
  }
];

const generateRoutesForOrganisations = function (orgs) {
  var additionalRoutes = [];
  for (const [key, value] of Object.entries(orgs)) {
    additionalRoutes.push({
      path: "/" + key,
      component: RegisterForm,
      props: { organiser: value }
    });
  }
  console.log(additionalRoutes);
  return additionalRoutes;
};

const routes = partialroutes.concat(generateRoutesForOrganisations(organisers));
const router = new VueRouter({
  mode: "history",
  routes
});
import Auth from "./api/auth.js";
import {trackEvent} from "./Analytics.vue"
router.beforeEach((to, from, next) => {
  if (to.auth && !Auth.authenticated) {
    // if route requires auth and user isn't authenticated
    return { "path": "/admin/login" }
  } else {
    trackEvent(to.path, {label: "PAGE", from: from.path})
    next()
  }
})



export default router;
